/** @jsx jsx */
import { jsx, Container, Flex, Button } from 'theme-ui';
import { keyframes } from '@emotion/core';
import { Link as getStarted } from 'react-scroll';
import Logo from 'components/logo';
import LogoDark from 'assets/logo.webp';
import { DrawerProvider } from '../../contexts/drawer/drawer.provider';
import MobileDrawer from './mobile-drawer';

import { Link } from 'components/link';
import data from '../footer/footer.data';
import { useRouter } from 'next/router';
import aboutdata from './about.data';

export default function Header({ className }) {

  const router = useRouter();

  return (
    <DrawerProvider>
      <header sx={styles.header} className={className} id="header">
        <Container sx={styles.container}>
          <Logo src={LogoDark} />
          <Flex as="nav" sx={styles.nav}>
            {router.pathname === '/aboutus' || router.pathname === '/osschat' ?
              aboutdata.menuItem.map(({ path, label }, i) => (
                  <Link                  
                    activeClass="active"
                    path={path}
                    key={i}
                    label={label}                  
                  />
                )) : data.menuItem.map(({ path, label }, i) => (
                  <Link                  
                    activeClass="active"
                    path={path}
                    key={i}
                    label={label}                  
                  />
                ))}
            {/* {menuItems.map(({ path, label }, i) => (
              <Link 
                activeClass="active"
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
              >
                {label}
              </Link>
            ))} */}
          </Flex>

          <Button
            className="donate__btn"
            variant="secondary"
            aria-label="Get Started"
          >
            {/* <getStarted
                activeClass="active"
                to='getstarted'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}                
              > 
            
            </getStarted> */}
            <a  href='https://register.onlinesoftservices.com'>
            Get Started </a>
          </Button>

          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }

  to {
    position: absolute;
    opacity: 1;
    Transition: all 0.4s ease;
  }
`;

const styles = {
  header: {    
    color: 'text',
    fontWeight: 'body',
    py: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    Transition: 'all 0.4s ease',
    animation: `${positionAnim} 0.4s ease`,
    '.donate__btn': {      
      borderColor: '#f86e04',
      fontWeight: 'bold',      
      flexShrink: 0,
      mr: [15, 20, null, null, 0],
      ml: ['auto', null, null, null, 0],
    },
    '&.sticky': {      
      position: 'fixed',
      backgroundColor: 'background',
      color: '#000000',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: 3,
      'nev > a': {
        color: 'text',
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      color: '#202124',
      textDecoration: 'none',
      fontSize: 2,
      fontWeight: 'body',
      px: 5,
      cursor: 'pointer',
      lineHeight: '1.2',
      Transition: 'all 0.15s',
      '&:hover': {
        color: 'primary',
      },
      '&.active': {
        color: 'primary',
      },
    },
  },
};
