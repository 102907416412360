import Chat from 'assets/widget/chat.svg';
import Community from 'assets/widget/community.svg';
import Github from 'assets/widget/github.svg';
import Youtube from 'assets/widget/youtube.svg';

export default {
  widgets: [
    {
      id: 1,
      iconSrc: Chat,
      altText: 'Chat',
      title: 'Create Your Own Chat Communication',
      description:
        'Create your own free chat communication. And build your community',
    },
    {
      id: 2,
      iconSrc: Community,
      altText: 'Community',
      title: 'Create Your Own Community',
      description:
        'Create your own free community or you can approach for join this platform. If OSS found 10K users in your own community you will be get 10K instant amount.',
    },
    {
      id: 3,
      iconSrc: Youtube,
      altText: 'YouTube',
      title: 'YouTube Access',
      description:
        'Users can upload your skills video which you want to explain any IT Skills topic after verify via oss. If OSS found on your video views at least 10K again you will be getting instant amount.',
    },
    {
      id: 4,
      iconSrc: Github,
      altText: 'SourceCode ',
      title: 'Free Source Code Access',
      description:
        'Users can get the some source code access free. After subscribe OSS chanel on YouTube then you should be input 3rd times 3 digit OSS code then you can get the source code instantly in your computer.',
    },
  ],
  menuItem: [
    {
      path: '/',
      label: 'Home',
    },
    {
      path: '/aboutus',
      label: 'AboutUS',
    },
    {
      path: '#howitworks',
      label: 'How It Works',
    },
    {
      path: '#training',
      label: 'Training',
    },   
    {
      path: '#joincommunity',
      label: 'Join Community',
    },
    {
      path: '#blog',
      label: 'Blog',
    },
  ],
};
