export default {
   menuItem: [
    {
      path: '/',
      label: 'Home',
    },
    {
      path: 'aboutus',
      label: 'AboutUS',
    },
    {
      path: 'osschat',
      label: 'OSSChat',
    }
  ] 
};
  